jQuery(document).ready(function($) {

  //init gsap
  gsap.registerPlugin(ScrollTrigger)
  let mm = gsap.matchMedia();

  //menu animations
  $('#burger').on('click', ()=>{
    
    if ($('#burger').hasClass('open')) {
      $('#burger').removeClass('open')
      menuTl.reverse()
    } else {
      $('#burger').addClass('open')
      menuTl.play()
    }
  })

  let menuTl = gsap.timeline({
    paused: true,
    onReverseComplete: function() {
      submenus.forEach((submenu, index) => {
        tween[index].reverse();
      });
    }
  });

  mm.add("(min-width: 768.001px)", (context) => {
    menuTl
      .to('.menu_container', {
        translateX: '0%',
        duration: .8,
        ease: 'Power2.inOut',
      })
      .to('.menu_container .content', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .logo', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .menu-item', {
        duration: 0.6,
        opacity: 1,
        ease: 'Power2.inOut',
        stagger: 0.1
      }, '-=0.1')
  })

  mm.add("(max-width: 768px)", (context) => {
    menuTl
      .to('.menu_container', {
        translateX: '0%',
        duration: .8,
        ease: 'Power2.inOut',
      })
      .to('.menu_container .content', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .logo', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .menu-item', {
        duration: .6,
        opacity: 1,
        ease: 'Power2.inOut',
        stagger: 0.1
      }, '-=0.1')
  })



  //sub menus animations
  var submenus = gsap.utils.toArray('.sub-menu'),
    tween = [];

  submenus.forEach((submenu, index) => {
    tween.push(
      gsap.from(
        submenu,
        {
          duration: 0.25,
          height: 0,
          paused: true,
          reversed: true,
          ease: 'Power2.inOut'
        }
      )
    )
  });
  
  let submenus_triggers = $('#primary-menu').find('a[href="#"]')

  submenus_triggers.each(function(i) {
    $(this).on('click', function(e) {
      e.preventDefault();

      submenus_triggers.each(function(j) {
        if (i != j)
          !tween[j].reversed() && tween[j].reverse();
      })

      tween[i].reversed() ? tween[i].play() : tween[i].reverse();
    });
  });


  //experience animation
  if ($('.experience')) {
    mm.add("(min-width: 768.001px)", () => {

      gsap.fromTo('.experience .parallax ', {
        translateY: '-35%',
      },{
        translateY: '35%',
        ease: 'none',
        scrollTrigger: {
          scrub: 0,
          // markers: true,
          trigger: '.experience',
          start: `top center`,
          end: `bottom center`,
        }
      });

    })    
  }


  //room gallery
  if ($('.rooms_gallery')) {
    new Swiper('.room_slider .content_video', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    new Swiper('.room_slider .content_text', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    new Swiper('.room_slider .content_right', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    //room image slide reveal
    gsap.fromTo('.room_slider .reveal-clip',
      {
        clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
      },
      {
        duration: 2,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: 'Power2.easeOut',
        scrollTrigger: {
          trigger: '.room_slider .reveal-clip img',
          start: 'center+=20% bottom',
          // markers: true,
        }
      }
    );
  }


  //reviews gallery
  if ($('.reviews')) {
    let reviewsIndex = 0
    const reviewsSlides = $('.reviews .hidden')

    $('#review_prev').on('click', ()=>{
      reviewsIndex = reviewsIndex==0 ? reviewsSlides.length-1 : reviewsIndex-1

      gsap.to('.reviews .slide.main', {
        opacity: 0,
        duration: .3,
        onComplete: () => {
          $('.reviews .slide.main').children('.review').html(
            $(reviewsSlides[reviewsIndex]).children('.review').html()
          )

          $('.reviews .slide.main').children('.author').text(
            $(reviewsSlides[reviewsIndex]).children('.author').text()
          )

          gsap.to('.reviews .slide.main', {
            opacity: 1,
            duration: .3,
          })
        }
      })

      gsap.to('.reviews .slide.main', {
        height: $(reviewsSlides[reviewsIndex]).innerHeight(),
        duration: .5,
        ease: 'none',
      })
    })

    $('#review_next').on('click', ()=>{
      reviewsIndex = reviewsIndex==reviewsSlides.length-1 ? 0 : reviewsIndex+1
      console.log('aaaaaaaa');

      gsap.to('.reviews .slide.main', {
        opacity: 0,
        duration: .3,
        onComplete: () => {
          $('.reviews .slide.main').children('.review').html(
            $(reviewsSlides[reviewsIndex]).children('.review').html()
          )

          $('.reviews .slide.main').children('.author').text(
            $(reviewsSlides[reviewsIndex]).children('.author').text()
          )

          gsap.to('.reviews .slide.main', {
            opacity: 1,
            duration: .3,
          })
        }
      })

      gsap.to('.reviews .slide.main', {
        height: $(reviewsSlides[reviewsIndex]).innerHeight(),
        duration: .5,
        ease: 'none',
      })
    })

    $('.reviews .slide.main').css('height', $(reviewsSlides[reviewsIndex]).innerHeight())
    $(window).on('resize', ()=>{
      $('.reviews .slide.main').css('height', $(reviewsSlides[reviewsIndex]).innerHeight())
    })    
  }



  // fade animations
  let fades = gsap.utils.toArray('.fade');

  if ( fades.length > 0 ) {
    fades.forEach((text) => {
      let delay = text.getAttribute('data-delay') || 0
      let start = text.getAttribute('data-start') || 'top bottom'

      gsap.from(
        text,
        {
          delay: delay,
          duration: 1,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: text,
            start: start,
          }
        }
      );
    });
  }

  // fade up animations
  let texts = gsap.utils.toArray('.fade-up');

  if ( texts.length > 0 ) {
    texts.forEach((text) => {
      let delay = text.getAttribute('data-delay') || 0
      let start = text.getAttribute('data-start') || 'top bottom'

      gsap.from(
        text,
        {
          delay: delay,
          duration: 1,
          autoAlpha: 0,
          y: 45,
          scrollTrigger: {
            trigger: text,
            start: start,
          }
        }
      );
    });
  }
})